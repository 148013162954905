@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

body {
  padding: 0rem;
  color: #000;
}

a {
  color: #0096d6;
}
h1 {
  color: #0096d6;
  /* text-transform: capitalize; */
  margin: 30px 0px 20px 0px;
  font-size: 35px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}
h2 {
  font-size: 26px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #1e1320;
}
h3 {
  font-size: 25px;
  font-weight: 300;
  border-bottom: 1px solid #ccc;
  font-family: 'Roboto', sans-serif;
}
p {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}
img {
  max-width: 100%;
}
.banner-caousel .carousel {
  padding: 0px;
}
.btn-primary {
  background: #1d7260;
  padding: 5px 20px;
}

.contact-info {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 30px;
  position: relative;
}

.contact-info span {
  display: block;
}
.contact-info span a {
  color: #333;
  text-decoration: none;
}

.contact-info span a:hover {
  color: #f59411;
}
.contact-info span svg {
  margin-right: 10px;
  color: #00853c;
}
.contact-info span a {
  margin-right: 30px;
}
.top-icons-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.top-icons-links a {
  padding: 0px 12px;
  display: inline-block;
  line-height: 1px;
  border-left: 1px solid #e9e9e924;
  margin-left: 10px;
}
.top-icons-links a:hover {
  background: #f59411;
}
.top-icons-links a:hover i {
  color: 000;
}

.top-icons-links a:first-child {
  border-left: 0;
}
.top-icons-links a {
  background: #3c446a;
  padding: 10px 15px;
  font-size: 18px;
  color: #fff;
}

.navbar {
  padding: 0px !important;
}

.navbar-toggler-icon {
  display: block;
  height: 34px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 34px;
  text-align: center;
  background-position: center;
}

.navbar-toggler {
  padding: 0px;
  margin: 0px;
}
.navbar-toggler:focus,
.navbar-toggler:visited {
  outline: none;
  border: none;
}

.navbar .navbar-nav .nav-link {
  color: #1c2f38;
  font-size: 18px;
  font-weight: 300;
  text-transform: capitalize;
  border: 1px solid transparent;
  margin: 0px 5px;
  padding: 4px 8px;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active:focus,
.navbar .navbar-nav .nav-link.active,
.navbar.navbar-light .navbar-nav .nav-link.active {
  color: #00853c;
  border: 1px solid #00853c;
}
.navbar-brand {
  margin-right: 3rem !important;
}
.navbar .navbar-brand img {
  padding: 0;
  margin: 0px 0px 0px -5px;
  float: left;
}
.navbar .navbar-brand span {
  text-transform: uppercase;
  color: #3c446a;
  float: left;
  font-size: 40px;
  width: 140px;
  padding: 10px 0px 0px 0px;
  text-align: center;
  font-family: titling-gothic-fb-compressed, sans-serif;
  font-weight: 400;
}
.navbar .navbar-brand span span {
  display: block;
  color: #000;
  padding: 0px;
  font-size: 12px;
  font-family: museo-sans, sans-serif;
  margin: -14px 0px 0px 0px;
}
.services {
  padding: 20px 0px 60px 0px;
}
/* h1.heading {
  text-transform: capitalize;
  margin: 20px 0px 20px 0px;
  font-size: 50px;
  font-family: titling-gothic-fb-compressed, sans-serif;
  font-weight: 300;
} */
.canada-assessment-form h2 span span {
  color: #e827a5;
}
.self-assessment-button {
  background: transparent;
  color: #000;
  text-decoration: none;
  padding: 6px 30px;
  font-size: 34px;
  border-radius: 5px;
  margin: 10px auto 0px auto;
  border: 1px solid #333;
  font-family: 'Flavors', cursive;
  /* text-transform: uppercase; */
}
.self-assessment-button:hover {
  border-radius: 5px;
  background: transparent;
  color: #222;
}
.vision {
  color: #d2cce5;
}
.membership {
  padding: 20px 0 60px;
  margin-bottom: 40px;
  margin-top: 20px;
}
h2.membership-heading {
  color: #1c2f38;
  text-align: center;
  text-transform: capitalize;
  padding: 0px 0px 30px 0px;
  font-size: 40px;
  margin: 10px 0px 0px 0px;
  font-weight: 500;
}
.membership .maara {
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .membership .maara {
    text-align: center;
  }
  .service + .service {
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 768px) {
  .membership .iccrc {
    text-align: center;
  }
}

/***********/
.footer-container {
  border-top: 1px solid rgb(0, 80, 213);
  border-bottom: 1px solid rgb(0, 80, 213);
  min-height: 200px;
  margin: 30px 0px 0px 0px;
  padding: 30px 0px 0px 0px;
  background: #0d7db2;
}

.footer-content {
  padding: 0px 20px 40px 0px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
}
.footer-col h3 {
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 24px;
}
.footer-list {
  color: #fff;
  list-style: none;
  padding: 0px;
}
.footer-container .footer-col input {
  outline: none;
}
.footer-container .footer-col label {
  color: #fff;
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
}

.newsletter-form {
  width: 200px;
}
.footer-container .footer-col .submit-btn {
  border: 0px;
  background: none;
  height: 12px;
  display: inline-block;
  position: relative;
  top: -28px;
  right: -161px;
  color: #fff;
  font-size: 13px;
}
.footer-container .footer-col input {
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
}
.footer-list li {
  margin-bottom: 5px;
}
.footer-list li a {
  color: #fff;
  text-decoration: none;
}
.footer-list li a:hover {
  color: #0096d6;
}
.footer-navigation {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}
.footer-navigation a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
}
.footer-navigation a:hover,
.footer-navigation a.active {
  color: #000;
}
.footer-content + .footer-content {
  padding-top: 20px;
}
.footer-content time {
  margin: 0px 0px 0px 7px;
}

.footer-content small {
  font-size: 15px;
}
.about-img img {
  width: 100%;
  border-radius: 100% 100% 0px 0px;
}

@media only screen and (max-width: 768px) {
  .about-img {
    margin-bottom: 20px;
  }
  .copyright {
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .contact-info {
    display: none;
  }
  .navbar .navbar-nav .nav-link {
    border: 0px;
  }
  .navbar .navbar-nav .nav-link:hover,
  .navbar .navbar-nav .nav-link.active:focus,
  .navbar .navbar-nav .nav-link.active,
  .navbar.navbar-light .navbar-nav .nav-link.active {
    color: #00853c;
    border: 0px;
  }
}

.contact-address address span {
  display: block;
  font-size: 15px;
}

.contact-phone p span {
  display: block;
  font-size: 15px;
}
.contact-phone a,
.contact-email a {
  text-decoration: none;
  color: #00853c;
}
.contact-phone a:hover,
.contact-email a:hover {
  color: #32121e;
}
.card {
  height: 100%;
  /* box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%); */
  color: #000 !important;
  background-image: linear-gradient(0deg, #32121e, #1e3c83);
}
.card p {
  color: #fff;
}
.card h2 {
  color: #c6cc62;
}
.card-body {
  padding: 0.86rem 1rem;
}

.customer-reviews h2 {
  font-size: 2.6rem;
  font-weight: 700;
  text-align: center;
}
.review {
  background: #6abae3;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  text-align: center;
}
.review:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.img-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.person-img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}
.quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateY(25%);
  background: #4521cb;
  color: #fff;
}
.img-container::before {
  content: '';
  width: 100%;
  height: 100%;
  background: #4521cb;
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  border-radius: 50%;
}
.author {
  margin-bottom: 0.25rem;
  color: #4521cb;
}

.info {
  margin-bottom: 0.75rem;
}
.prev-btn,
.next-btn {
  color: #20179c;
  font-size: 1.25rem;
  background: transparent;
  border-color: transparent;
  margin: 0 0.5rem;
  transition: 0.5s;
  cursor: pointer;
}
.prev-btn:hover,
.next-btn:hover {
  color: #000;
}
